import React from 'react';
import logo from './assets/images/cg-logo-new.png';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">
              <img src={logo} alt="Logo" style={{ width: '300px' }} />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:contact@challengegroup.org">
            <div className="cta">Send us an email</div>
          </a>
        </div>
      </div>
    );
  }
}

export default App;